import { useTranslation } from 'react-i18next'
import {
    PdfTemplateDrawingContestScoolRound,
    PdfTemplateDrawingContestNational,
    PdfTemplateDrawingContestInternationalParticipant,
    PdfTemplateDrawingContestInternationalJuniorDesigner,
    PdfTemplateDrawingContest2021Base,
    PdfTemplateDrawingContest2022, PdfTemplateDrawingContest2023, PdfTemplateDrawingContest2024,
} from './CustomPdfTemplates'
import {
    CustomCert,
    drawingContest2020ScoolroundParticipation,
    drawingContest2020ScoolroundTrainerChoice,
    drawingContest2020ScoolroundPlaced,
    drawingContest2020National,
    drawingContest2020InterationalParticipation,
    drawingContest2020JuniorDesigner,
    drawingContest2021ScoolroundParticipation,
    drawingContest2021ScoolroundTrainerChoice,
    drawingContest2021ScoolroundPlaced,
    drawingContest2021National,
    drawingContest2021InterationalParticipation,
    drawingContest2021JuniorDesigner,
    drawingContest2022InterationalParticipation,
    drawingContest2022JuniorDesigner,
    drawingContest2022National,
    drawingContest2022ScoolroundParticipation,
    drawingContest2022ScoolroundPlaced,
    drawingContest2022ScoolroundTrainerChoice,
    drawingContest2023ScoolroundParticipation,
    drawingContest2023ScoolroundTrainerChoice,
    drawingContest2023ScoolroundPlaced,
    drawingContest2023National,
    drawingContest2023InterationalParticipation, drawingContest2023JuniorDesigner, drawingContest2024ScoolroundParticipation, drawingContest2024ScoolroundTrainerChoice, drawingContest2024ScoolroundPlaced, drawingContest2024National, drawingContest2024InterationalParticipation, drawingContest2024JuniorDesigner,
} from './CustomCertData'
import { InventoryState } from 'lib/liveops/reducers/client/inventory'
import React from 'react'
import { useNotifications } from 'features/notifications'
import { certificateTypeColorMap, downloadFile, formatDate } from '../certificateHelpers'
import styled from 'styled-components/macro'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { ActionCard } from 'components/action-card'
import { Img } from 'components/Img'
import logiscoolLogo from '../logiscool-logo.png'
import { TypedTFunction, useTranslate } from 'lib/i18n/useTranslate'
import { useUserState } from 'contexts/UserContext'
import { CertificateRibbon } from '../CertificateRibbon'
import { CertificateType } from '../certificateTypes'
import { CertificateDialog } from '../CertifiateDialog'
import { generateId, generatePdf } from '../PdfGenerator'
import { LocalisationKey } from 'lib/i18n'
import { filterNulls } from 'utils/arrayUtilts'

export const DRAWING_CONTEST_2020_SCHOOLROUND_ID = '5fc790b56ef94b00091a6148'
export const DRAWING_CONTEST_2020_NATIONAL_ID = '5fc790e9e8200100093514b8'
export const DRAWING_CONTEST_2020_INTERNATIONAL_ID = '5fd776a74a7e0a00092ea597'

export const DRAWING_CONTEST_2021_SCHOOLROUND_ID = '61af36449ee42e0f33d77988'
export const DRAWING_CONTEST_2021_NATIONAL_ID = '61af36529ee42ebe46d7798c'
export const DRAWING_CONTEST_2021_INTERNATIONAL_ID = '61af3667663b061ef539af7b'

export const DRAWING_CONTEST_2022_SCHOOLROUND_ID = '6377a7898900654454a986ed'
export const DRAWING_CONTEST_2022_NATIONAL_ID = '6377a79bc6a946edb6fe1845'
export const DRAWING_CONTEST_2022_INTERNATIONAL_ID = '6377a7b3c166c0f1923a9493'

export const DRAWING_CONTEST_2023_SCHOOLROUND_ID = '6552940f612ca13257058e3a'
export const DRAWING_CONTEST_2023_NATIONAL_ID = '65529417612ca1aa92058e96'
export const DRAWING_CONTEST_2023_INTERNATIONAL_ID = '6552941f612ca1cde3058ea9'

export const DRAWING_CONTEST_2024_SCHOOLROUND_ID = '66fdb0ceff66bcce21aafbb5'
export const DRAWING_CONTEST_2024_NATIONAL_ID = '66fdb0c5ff66bcce21aafb91'
export const DRAWING_CONTEST_2024_INTERNATIONAL_ID = '66fdb0baff66bcce21aafb74'


export type DrawingContestRound = 'schoolRound' | 'nationalRound' | 'internationalRound'

const drawingContests = [
    {
        year: 2020,
        schoolRound: {
            inventoryItem: DRAWING_CONTEST_2020_SCHOOLROUND_ID,
            participantCertificate: drawingContest2020ScoolroundParticipation,
            trainersChoiceCertificate: drawingContest2020ScoolroundTrainerChoice,
            winnerCertificate: drawingContest2020ScoolroundPlaced,
        },
        nationalRound: {
            inventoryItem: DRAWING_CONTEST_2020_NATIONAL_ID,
            certificate: drawingContest2020National,
        },
        internationalRound: {
            inventoryItem: DRAWING_CONTEST_2020_INTERNATIONAL_ID,
            participantCertificate: drawingContest2020InterationalParticipation,
            juniorDesignerCertificate: drawingContest2020JuniorDesigner,
        },
    },
    {
        year: 2021,
        schoolRound: {
            inventoryItem: DRAWING_CONTEST_2021_SCHOOLROUND_ID,
            participantCertificate: drawingContest2021ScoolroundParticipation,
            trainersChoiceCertificate: drawingContest2021ScoolroundTrainerChoice,
            winnerCertificate: drawingContest2021ScoolroundPlaced,
        },
        nationalRound: {
            inventoryItem: DRAWING_CONTEST_2021_NATIONAL_ID,
            certificate: drawingContest2021National,
        },
        internationalRound: {
            inventoryItem: DRAWING_CONTEST_2021_INTERNATIONAL_ID,
            participantCertificate: drawingContest2021InterationalParticipation,
            juniorDesignerCertificate: drawingContest2021JuniorDesigner,
        },
    },
    {
        year: 2022,
        schoolRound: {
            inventoryItem: DRAWING_CONTEST_2022_SCHOOLROUND_ID,
            participantCertificate: drawingContest2022ScoolroundParticipation,
            trainersChoiceCertificate: drawingContest2022ScoolroundTrainerChoice,
            winnerCertificate: drawingContest2022ScoolroundPlaced,
        },
        nationalRound: {
            inventoryItem: DRAWING_CONTEST_2022_NATIONAL_ID,
            certificate: drawingContest2022National,
        },
        internationalRound: {
            inventoryItem: DRAWING_CONTEST_2022_INTERNATIONAL_ID,
            participantCertificate: drawingContest2022InterationalParticipation,
            juniorDesignerCertificate: drawingContest2022JuniorDesigner,
        },
    },
    {
        year: 2023,
        schoolRound: {
            inventoryItem: DRAWING_CONTEST_2023_SCHOOLROUND_ID,
            participantCertificate: drawingContest2023ScoolroundParticipation,
            trainersChoiceCertificate: drawingContest2023ScoolroundTrainerChoice,
            winnerCertificate: drawingContest2023ScoolroundPlaced,
        },
        nationalRound: {
            inventoryItem: DRAWING_CONTEST_2023_NATIONAL_ID,
            certificate: drawingContest2023National,
        },
        internationalRound: {
            inventoryItem: DRAWING_CONTEST_2023_INTERNATIONAL_ID,
            participantCertificate: drawingContest2023InterationalParticipation,
            juniorDesignerCertificate: drawingContest2023JuniorDesigner,
        },
    },
    {
        year: 2024,
        schoolRound: {
            inventoryItem: DRAWING_CONTEST_2024_SCHOOLROUND_ID,
            participantCertificate: drawingContest2024ScoolroundParticipation,
            trainersChoiceCertificate: drawingContest2024ScoolroundTrainerChoice,
            winnerCertificate: drawingContest2024ScoolroundPlaced,
        },
        nationalRound: {
            inventoryItem: DRAWING_CONTEST_2024_NATIONAL_ID,
            certificate: drawingContest2024National,
        },
        internationalRound: {
            inventoryItem: DRAWING_CONTEST_2024_INTERNATIONAL_ID,
            participantCertificate: drawingContest2024InterationalParticipation,
            juniorDesignerCertificate: drawingContest2024JuniorDesigner,
        },
    },
]

export const createDrawingContestCustomCertificate = (
    year: number,
    place: string,
    round: DrawingContestRound,
    schoolName: string,
) => {
    const contestData = drawingContests.find(contest => contest.year === year)

    if (!contestData) {
        throw new Error('Contest data not found.')
    }

    switch (round) {
        case 'schoolRound':
            if (place === 'participation') {
                return contestData.schoolRound.participantCertificate
            } else if (place === 'schoolroundtrainerschoice') {
                return contestData.schoolRound.trainersChoiceCertificate(schoolName)
            } else {
                return contestData.schoolRound.winnerCertificate(schoolName, place as any)
            }

        case 'nationalRound':
            return contestData.nationalRound.certificate(schoolName, place as any)

        case 'internationalRound':
            if (place === 'participation') {
                return contestData.internationalRound.participantCertificate
            } else {
                return contestData.internationalRound.juniorDesignerCertificate(place as any)
            }
    }
}

export const getCertFromInventroy = (inventory: InventoryState, schoolName: string) => {
    let customCerts: CustomCert[] = []

    for (const drawingContest of drawingContests) {
        const scoolRoundItem =
            inventory && inventory.find(item => item.item === drawingContest.schoolRound.inventoryItem)
        if (scoolRoundItem) {
            const cert = createDrawingContestCustomCertificate(
                drawingContest.year,
                scoolRoundItem.metadata.place,
                'schoolRound',
                schoolName,
            )
            if (cert) {
                customCerts.push(cert)
            }
        }

        const nationaRoundItem =
            inventory && inventory.find(item => item.item === drawingContest.nationalRound.inventoryItem)
        if (nationaRoundItem) {
            const cert = createDrawingContestCustomCertificate(
                drawingContest.year,
                nationaRoundItem.metadata.place,
                'nationalRound',
                schoolName,
            )
            if (cert) {
                customCerts.push(cert)
            }
        }

        const internationalRoundItem =
            inventory && inventory.find(item => item.item === drawingContest.internationalRound.inventoryItem)

        if (internationalRoundItem) {
            const cert = createDrawingContestCustomCertificate(
                drawingContest.year,
                internationalRoundItem.metadata.place,
                'internationalRound',
                schoolName,
            )
            if (cert) {
                customCerts.push(cert)
            }
        }
    }

    return customCerts
}

interface ItemProps {
    cert: CustomCert
    fullName: string
    isOpening: boolean
    setOpening: (isOpening: boolean) => void
}

export const DrawingContestCertItem: React.FC<ItemProps> = ({ cert, fullName, isOpening, setOpening }) => {
    const t = useTranslate()
    const { i18n } = useUserState()
    const { notify } = useNotifications()
    const [status, setStatus] = React.useState<'idle' | 'generating-pdf' | 'open-dialog'>('idle')
    const [imgData, setImgData] = React.useState<{ img: string; blob: Blob }>()

    const handleClick = () => {
        if (status === 'idle' && !isOpening) {
            setStatus('generating-pdf')
            setOpening(true)
        }
    }

    const handleGenerateSucces = (blob: Blob, imgData: string) => {
        setImgData({ img: imgData, blob })
        setStatus('open-dialog')
    }

    const handleDownload = () => {
        if (!imgData) return
        let filename = `Logiscool ${t('certificate::simpletitle')}`
        const translations = getCertificateTranslations(t, cert)
        if (translations) {
            const finishedAt = formatDate(cert.date, i18n.language)
            filename = `Logiscool ${t('certificate::simpletitle')} – ${translations.certName} ${finishedAt}`
        }
        filename = filename.replace(/\./g, '')
        downloadFile(imgData.blob, filename)
    }

    const translations = getCertificateTranslations(t, cert)

    const courseInfo = React.useMemo(() => CustomCerts.find(course => course.code === cert.certCode), [cert.certCode])

    if (!translations.certName || !Object.values(translations.texts).filter(filterNulls)) {
        console.log(`missing translations for ${cert.certName}`)
        return null
    }

    const finishedAt = formatDate(cert.date, i18n.language)
    const { color, highlightColor } = certificateTypeColorMap['event']

    return (
        <>
            <Root onClick={handleClick} certificateType={'event'}>
                <Inner>
                    <StyledCertificateRibbon color={color} highlightColor={highlightColor} />
                    <TextWrap>
                        <Title>{translations.certName}</Title>
                        <Text variant="caption">{finishedAt}</Text>
                    </TextWrap>
                    <ThumbnailWrap>
                        <Thumbnail src={courseInfo?.thumbnail || logiscoolLogo} />
                    </ThumbnailWrap>
                </Inner>
                {status === 'generating-pdf' && (
                    <LoaderWrap>
                        <Loader size="1rem" />
                    </LoaderWrap>
                )}
            </Root>
            {status === 'generating-pdf' && (
                <CustomPdfGenerator
                    cert={cert}
                    fullName={fullName}
                    onSuccess={handleGenerateSucces}
                    onError={() => {
                        setStatus('idle')
                        notify(t('error::somethingWentWrong'), { variant: 'error' })
                    }}
                />
            )}
            {imgData && (
                <CertificateDialog
                    open={status === 'open-dialog'}
                    close={() => {
                        setStatus('idle')
                        setOpening(false)
                    }}
                    imgData={imgData.img}
                    title={translations.certName}
                    download={handleDownload}
                />
            )}
        </>
    )
}

interface CustomPdfGeneratorProps {
    cert: CustomCert
    fullName: string
    onSuccess: (blob: Blob, imgData: string) => void
    onError: (code: 'NO_COURSE_INFO' | 'GENERAL') => void
    language? : string
}

export const CustomPdfGenerator: React.FC<CustomPdfGeneratorProps> = ({ cert, fullName, language, onSuccess, onError }) => {
    const { t } = useTranslation() 
    const { i18n } = useUserState()
    const id = React.useRef(generateId())

    const courseInfo = React.useMemo(() => CustomCerts.find(course => course.code === cert.certCode), [cert.certCode])

    const translations = React.useMemo(() => getCertificateTranslations(t, cert), [cert, t])

    React.useEffect(() => {
        let ignore = false

        if (!courseInfo) {
            onError('NO_COURSE_INFO')
            return
        }

        generatePdf(id.current)
            .then(({ blob, imgData }) => {
                if (!ignore) {
                    onSuccess(blob, imgData)
                }
            })
            .catch(e => {
                if (!ignore) {
                    console.error(e)
                    onError('GENERAL')
                }
            })

        return () => {
            ignore = true
        }
    }, [onError, onSuccess, translations.texts, courseInfo])

    if (!courseInfo) return null

    const finishedAt = formatDate(cert.date, i18n.language)

    return (
        <courseInfo.template
            translations={translations.texts}
            finishedAt={finishedAt}
            name={fullName}
            id={id.current}
            locale={language || i18n.locale}
        />
    )
}

const getCertificateTranslations = (t: TypedTFunction, cert: CustomCert) => {
    return {
        certName: t(cert.certName as LocalisationKey, { defaultValue: null }),
        ...(cert.texts && {
            texts: {
                ...(cert.texts.blurb && {
                    blurb: Array.isArray(cert.texts.blurb)
                        ? t(cert.texts.blurb[0] as LocalisationKey, { ...cert.texts.blurb[1], defaultValue: null })
                        : t(cert.texts.blurb as LocalisationKey),
                }),
                ...(cert.texts.section_first && {
                    section_first: t(cert.texts.section_first as LocalisationKey, { defaultValue: null }),
                }),
                ...(cert.texts.section_second && {
                    section_second: t(cert.texts.section_second as LocalisationKey, { defaultValue: null }),
                }),
            },
        }),
    }
}

interface CustomCourse {
    code: string
    template: typeof PdfTemplateDrawingContestScoolRound
    thumbnail: string
}

const CustomCerts: CustomCourse[] = [
    {
        code: 'drawUnit',
        template: PdfTemplateDrawingContestScoolRound,
        thumbnail: '/certificate/thumbnails/drawingcontest2020.png',
    },
    {
        code: 'drawNational',
        template: PdfTemplateDrawingContestNational,
        thumbnail: '/certificate/thumbnails/drawingcontest2020.png',
    },
    {
        code: 'drawInternParticipant',
        template: PdfTemplateDrawingContestInternationalParticipant,
        thumbnail: '/certificate/thumbnails/drawingcontest2020.png',
    },
    {
        code: 'drawInternJuniorDesigner',
        template: PdfTemplateDrawingContestInternationalJuniorDesigner,
        thumbnail: '/certificate/thumbnails/drawingcontest2020.png',
    },
    {
        code: 'draw2021-base',
        template: PdfTemplateDrawingContest2021Base,
        thumbnail: '/certificate/thumbnails/drawingcontest2021.png',
    },
    {
        code: 'drawIntern-2021', // Marketing: no frame differences between that and draw2021-base
        template: PdfTemplateDrawingContest2021Base,
        thumbnail: '/certificate/thumbnails/drawingcontest2021.png',
    },
    {
        code: 'drawing-contest-2022',
        template: PdfTemplateDrawingContest2022,
        thumbnail: '/certificate/thumbnails/drawingcontest2022.png',
    },
    {
        code: 'drawing-contest-2023',
        template: PdfTemplateDrawingContest2023,
        thumbnail: '/certificate/thumbnails/drawingcontest2023.png',
    },
    {
        code: 'drawing-contest-2024',
        template: PdfTemplateDrawingContest2024,
        thumbnail: '/certificate/thumbnails/drawingcontest2024.png',
    },
]

const StyledCertificateRibbon = styled(CertificateRibbon)`
    position: relative;
    top: -${props => props.theme.spacing(3)};
    width: 100%;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.25));
    transition: transform ${props => props.theme.transitions.duration.standard}ms;
`

const Root = styled(ActionCard)<{ certificateType: CertificateType }>`
    position: relative;
    overflow: visible;
    &:hover,
    &:focus-visible {
        ${StyledCertificateRibbon} {
            transform: translateY(-3px);
        }
    }
`

const LoaderWrap = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing(2)};
    right: ${props => props.theme.spacing(2)};
`

const TextWrap = styled.div`
    align-self: center;
`

const ThumbnailWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding: ${props => props.theme.spacing(0, 3, 3, 0)};
`

const Thumbnail = styled(Img)`
    display: block;
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    object-position: bottom right;
`

const Inner = styled.div`
    display: grid;
    grid-template-columns: 3.25rem 8fr 6fr;
    gap: ${props => props.theme.spacing(3)};
    padding-inline-start: ${props => props.theme.spacing(4)};
`

const Title = styled(Text)`
    font-weight: 600;
    line-height: 1.2;
`
